<template>
  <div class="container text-center" style="margin-top: 5%">
    <img
      class="m-4"
      src="../assets/OHA-logo.png"
      alt=""
      style="width: auto; height: 200px"
    />
    <h1>404</h1>
    <h3>NOT FOUND</h3>
    If the problem persists, please contact Origin Health support at
    <a
      href="mailto:support@originhealth.ai?subject=404 Not Found Problem"
      target="_top"
      >support@originhealth.ai</a
    >
  </div>
</template>
